import React from 'react';

function Projects() {
  return (
    <section id="projects">
      <h2>Projets</h2>
      {/* Ajoutez vos projets ici */}
    </section>
  );
}

export default Projects;
