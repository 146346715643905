import React from 'react';

function About() {
  return (
    <section id="about">
      <h2>À propos de moi</h2>
      <p>Je suis une développeuse web passionnée par...</p>
    </section>
  );
}

export default About;
