import React from 'react';

function Skills() {
  return (
    <section id="skills">
      <h2>Compétences</h2>
      <ul>
        <li>HTML5</li>
        <li>CSS3</li>
        <li>JavaScript</li>
        <li>React</li>
        {/* Ajoutez vos compétences ici */}
      </ul>
    </section>
  );
}

export default Skills;
