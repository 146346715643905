import React from 'react';

function Contact() {
  return (
    <section id="contact">
      <h2>Contact</h2>
      <p>Vous pouvez me contacter à l'adresse email suivante : example@email.com</p>
    </section>
  );
}

export default Contact;
